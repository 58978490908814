import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { TourGallery } from "../components/tourgallery/tourgallery"

import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core"

export const data = graphql`
  query {
    allStrapiTours {
      edges {
        node {
          summary
          slug
          title
          thumbnailoffset
          continents {
            name
          }
          categories {
            tag
          }
          datelist {
            selling
          }
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    strapiHomepage {
      hero {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    allStrapiContinents {
      edges {
        node {
          name
          label
        }
      }
    }
    allStrapiCategories {
      edges {
        node {
          tag
          label
        }
      }
    }
  }
`

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    selbox: {
      margin: "1rem",
      width: 280,
    },
    selboxWrapper: {
      margin: "1rem",
    },
  })
)

function Tours({ data }) {
  const classes = useStyles()
  const [selContinent, setSelContinent] = React.useState("")
  const [selCategory, setSelCategory] = React.useState("")

  const createFilteredtours = () => {
    var filteredtours = []

    data.allStrapiTours.edges.forEach(tour => {
      var foundcontinent = false
      var foundcategory = false
      tour.node.continents.forEach(continent => {
        if (selContinent == "" || continent.name == selContinent) {
          foundcontinent = true
          return
        }
      })
      tour.node.categories.forEach(category => {
        if (selCategory == "" || category.tag == selCategory) {
          foundcategory = true
          return
        }
      })
      // If match then add the tour
      if (foundcontinent && foundcategory) {
        filteredtours.push(tour)
      }
    })
    return filteredtours
  }

  const [filteredtours, setFilteredtours] = React.useState(
    createFilteredtours()
  )

  // Update the tours list when one of the filtering changes
  useEffect(() => {
    setFilteredtours(createFilteredtours())
  }, [selContinent, selCategory])

  return (
    <div>
      <Layout>
        <SEO title="Tours" image={data.strapiHomepage.hero.childImageSharp.fluid.src}/>
        <div id="filters" className={classes.selboxWrapper}>
          <Grid container>
            <Grid
              container
              item
              xs={12}
              sm={6}
              alignContent="center"
              justify="center"
            >
              <Autocomplete
                id="continent-filter"
                options={data.allStrapiContinents.edges} //List of dicts
                getOptionLabel={option => option.node.label} //key of each dict to the content to show
                className={classes.selbox}
                onChange={(event: any, newValue: string | null) => {
                  setSelContinent(newValue == null ? "" : newValue.node.name)
                  // setFilteredtours(createFilteredtours())
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Вибрати континент"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={6}
              alignContent="center"
              justify="center"
            >
              <Autocomplete
                id="category-filter"
                options={data.allStrapiCategories.edges} //List of dicts
                getOptionLabel={option => option.node.label} //key of each dict to the content to show
                className={classes.selbox}
                onChange={(event: any, newValue: string | null) => {
                  setSelCategory(newValue == null ? "" : newValue.node.tag)
                  // setFilteredtours(createFilteredtours())
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Вибрати категорію"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>

        <TourGallery>{filteredtours}</TourGallery>
      </Layout>
    </div>
  )
}

export default Tours
